




import Vue from "vue";
import { Component } from "vue-property-decorator";

import { axiosInstance as axios } from "@/store";

@Component
export default class TokenView extends Vue {
  async created(): Promise<void> {
    const token = this.$router.currentRoute.params.token;
    const whichToken = this.$router.currentRoute.path.split("/")?.[1];

    if (!token || !whichToken) return this.exit();

    switch (whichToken) {
      case "confirmAccount":
        return await this.confirmAccount(token);
      case "confirmUpdateMail":
        return await this.confirmUpdateMail(token);
      case "confirmDelete":
        return await this.confirmDelete(token);
      default:
        return this.exit();
    }
  }

  async confirmAccount(token: string): Promise<void> {
    try {
      await axios.post("/auth/user/confirmaccounttoken", {
        token,
      });
      await this.$store.direct.dispatch.authentication.whoami();
      this.$store.direct.commit.SHOW_TOAST({ text: this.$t("tokens.notifications.accountValidated").toString() });
      this.exit();
    } catch (err) {
      this.invalidToken();
    }
  }

  async confirmUpdateMail(token: string): Promise<void> {
    try {
      await axios.post("/auth/user/confirmupdatemailtoken", {
        token,
      });
      this.$store.direct.commit.SHOW_TOAST({ text: this.$t("tokens.notifications.emailUpdated").toString() });
      this.exit();
    } catch (err) {
      this.invalidToken();
    }
  }

  async confirmDelete(token: string): Promise<void> {
    try {
      await axios.post("/auth/user/confirmdeleteaccounttoken", {
        token,
      });
      this.$store.direct.commit.SHOW_TOAST({ text: this.$t("tokens.notifications.accountDeleted").toString() });
      this.$store.direct.dispatch.authentication.logout();
      this.exit();
    } catch (err) {
      this.invalidToken();
    }
  }

  invalidToken(): void {
    this.$store.direct.commit.SHOW_TOAST({ text: this.$t("tokens.notifications.tokenInvalid").toString() });
    this.exit();
  }

  exit(): void {
    this.$router.replace("/");
  }
}
